import React, { useEffect, useState } from 'react'
import TopNavbar from '../components/TopNavbar'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { changeBoard_tournamentData, changeBoard_versus } from '../Redux/slices/boardSlice'
import TournamentSelectionModal from '../components/Modal/TournamentSelectionModal'
import BonusSignUpModal from '../components/Modal/BonusSignUpModal'
import PaymentResponseModal from '../components/Modal/PaymentResponseModal'
import { ApiGet } from '../helpers/axiosFunctions'
import { changeProfile_UserData } from '../Redux/slices/profileSlice'
import { errorToast } from '../helpers/commonFunctions'
import AddMoneyModal from '../components/Modal/AddMoneyModal'

export default function Home() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { userData } = useSelector(state => state.profileData)
    const [tournamentSelectionModal, setTournamentSelectionModal] = useState(false)
    const [bonusSignUpModal, setBonusSignUpModal] = useState(false)
    const [addMoneyModal, setAddMoneyModal] = useState(false)

    const fetchUserData = async () => {
        await ApiGet("/user/profile")
            .then((result) => {
                dispatch(changeProfile_UserData(result?.data))
            })
            .catch((error) => {
                if (error.status === 405) {
                    dispatch(changeProfile_UserData(error?.data))
                    return setAddMoneyModal(true);
                }
                errorToast(error?.message)
            })
    }

    useEffect(() => {
        fetchUserData();
    }, [])

    useEffect(() => {
        if (userData) {
            if (userData?.isFirstSignup) {
                setBonusSignUpModal(true)
            }
        }
        dispatch(changeBoard_tournamentData({}))
    }, [userData])

    return (<>
        <div className='flex flex-col w-full'>
            <TopNavbar rules />
            <div className='w-full flex flex-1 flex-col flex-center p-7 text-center'>
                <img src="/media/images/tic_tac_toe_Logo.png" alt="zero_kataa_logo" />
                <h1 className='text-textYellow text-5xl'>ZERO KATAA</h1>
                <div
                    onClick={() => {
                        dispatch(changeBoard_versus("AI"))
                        navigate("/board-selection")
                    }}
                    className='flex items-center gap-5 mt-10 cursor-pointer border border-transparent rounded-full duration-300 hover:border-white/50'>
                    <div className='bg-textYellow rounded-full flex flex-center h-20 w-20'>
                        <img
                            src="https://img.freepik.com/premium-vector/young-man-face-avater-vector-illustration-design_968209-13.jpg?semt=ais_hybrid"
                            alt="avatar"
                            className='rounded-full h-14 w-14 object-cover'
                        />
                    </div>
                    <div className='text-4xl text-textYellow'>VS</div>
                    <div className='bg-textYellow rounded-full flex flex-center h-20 w-20 '>
                        <img
                            src="https://static.vecteezy.com/system/resources/thumbnails/025/271/424/small/robot-chatbot-generative-ai-free-png.png"
                            alt="avatar"
                            className='rounded-full h-14 w-14 object-cover'
                        />
                    </div>
                </div>
                <div
                    onClick={() => {
                        dispatch(changeBoard_versus("Human"))
                        navigate("/board-selection")
                    }}
                    className='flex items-center gap-5 mt-7 cursor-pointer border border-transparent rounded-full duration-300 hover:border-white/50'
                >
                    <div className='bg-textYellow rounded-full flex flex-center h-20 w-20'>
                        <img
                            src="https://img.freepik.com/premium-vector/young-man-face-avater-vector-illustration-design_968209-13.jpg?semt=ais_hybrid"
                            alt="avatar"
                            className='rounded-full h-14 w-14 object-cover'
                        />
                    </div>
                    <div className='text-4xl text-textYellow'>VS</div>
                    <div className='bg-textYellow rounded-full flex flex-center h-20 w-20'>
                        <img
                            src="https://img.freepik.com/premium-vector/young-man-face-avater-vector-illustration-design_968209-13.jpg?semt=ais_hybrid"
                            alt="avatar"
                            className='rounded-full h-14 w-14 object-cover'
                        />
                    </div>
                </div>
                <button className='theme-btn px-5 mt-7' onClick={() => setTournamentSelectionModal(true)} >TOURNAMENT</button>
                <description className="text-white mt-5">Earn More 4.08.058 Playing Online <br />refer and earn code - <span className='text-textYellow' >{userData?.referCode}</span></description>
            </div>
        </div>
        <TournamentSelectionModal
            isOpen={tournamentSelectionModal}
            setIsOpen={setTournamentSelectionModal}
        />
        <BonusSignUpModal
            isOpen={bonusSignUpModal}
            setIsOpen={setBonusSignUpModal}
        />
        <PaymentResponseModal />
        <AddMoneyModal
            isOpen={addMoneyModal}
            setIsOpen={setAddMoneyModal}
            notClosable
        />
    </>)
}
