import React from 'react'
import { Logout } from '../helpers/commonFunctions'
import { useSelector } from 'react-redux'

export default function ProfilePage() {
    const { userData } = useSelector(state => state.profileData)
    return (<div className='w-full flex flex-col items-center px-7'>
        <h1 className='text-textYellow text-4xl my-7'>Profile</h1>
        <div className="w-full">
            <h2 className='text-lg text-white/80'>Name : <span className='text-textYellow'>{userData?.name}</span></h2>
            <h2 className='text-lg text-white/80'>Email : <span className='text-textYellow'>{userData?.email}</span></h2>
            <h2 className='text-lg text-white/80'>Unique ID : <span className='text-textYellow'>{userData?.uniqueId}</span></h2>
        </div>
        <button className='theme-btn px-7 !py-0 mt-7' onClick={Logout}>LOGOUT</button>
    </div>)
}
