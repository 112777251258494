import React, { useEffect, useState } from 'react'
import TopNavbar from '../components/TopNavbar'
import { ApiDelete, ApiGet, ApiPost } from '../helpers/axiosFunctions'
import { errorToast, successToast } from '../helpers/commonFunctions'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export default function PlayerSearchingPage() {
    const navigate = useNavigate();
    const [roomData, setRoomData] = useState({});
    const { userData } = useSelector((state) => state.profileData);
    const [cancelLoading, setCancelLoading] = useState(false);
    const [isBothConnected, setIsBothConnected] = useState(false);

    const handleCancel = async () => {
        setCancelLoading(true);
        try {
            await ApiDelete(`/user/cancelRoom/${roomData?._id}`);
            navigate('/home');
        } catch (error) {
            errorToast(error?.message);
        } finally {
            setCancelLoading(false);
        }
    };

    const fetchRoomData = async () => {
        try {
            const result = await ApiGet(`/user/getRoom/${userData?.uniqueId}`);
            setRoomData(result?.data);
            localStorage.setItem('battleVersus', 'Human')
            if (result?.data?.senderId && result?.data?.receiverId) {
                setIsBothConnected(true);
            }
        } catch (error) {
            errorToast(error?.message);
            if (error?.status === 404) {
                navigate('/home');
            }
        }
    };

    useEffect(() => {
        if (isBothConnected) {
            ApiPost('/user/playing', { level: "3X3" })
                .then((res) => {
                successToast('Connected. Starting the match...')
                setTimeout(() => {
                    navigate(`/battle`);
                }, 3000);
            })
                .catch((error) => {
                    errorToast(error?.message);
                })
        }
    }, [isBothConnected])

    useEffect(() => {
        let timeoutId;

        const startFetching = async () => {
            if (userData?.uniqueId && !isBothConnected) {
                await fetchRoomData();
                timeoutId = setTimeout(startFetching, 2000);
            }
        };

        startFetching();

        // Cleanup function to stop the timeout when component unmounts
        return () => clearTimeout(timeoutId);
    }, [userData, isBothConnected]);

    return (
        <>
            <div className='w-full'>
                <TopNavbar isBackButton={false} />
                <div className='flex flex-1 flex-col flex-center h-full pb-16 px-7 text-center'>
                    <h1 className='text-3xl text-textYellow'>Looking for an Opponent...</h1>
                    <desc className='text-white mt-4'>Please wait while we find someone to test <br /> your skills.</desc>
                    <div className='bg-textYellow rounded-full flex flex-center h-16 w-16 mt-7'>
                        <img
                            src={roomData?.senderData?.image || "https://img.freepik.com/premium-vector/young-man-face-avater-vector-illustration-design_968209-13.jpg?semt=ais_hybrid"}
                            alt="avatar"
                            className='rounded-full h-12 w-12 object-cover'
                        />
                    </div>
                    <h3 className='text-2xl text-white mt-3'>{roomData?.senderData?.name}</h3>
                    <img
                        src='/media/images/VS_line.png'
                        className='my-10'
                    />
                    {roomData?.receiverData ?
                        <div className='bg-textYellow rounded-full flex flex-center h-16 w-16 text-white text-5xl'>
                            <img
                                src={roomData?.receiverData?.image || "https://img.freepik.com/premium-vector/young-man-face-avater-vector-illustration-design_968209-13.jpg?semt=ais_hybrid"}
                                alt="receiver avatar"
                                className='rounded-full h-12 w-12 object-cover'
                            />
                        </div>
                        :
                        <div className='bg-textYellow rounded-full flex flex-center h-16 w-16 text-white text-5xl circle-ripple'>
                            ?
                        </div>
                    }
                    <h3 className='text-2xl text-white mt-3'>{roomData?.receiverData?.name || "???"}</h3>
                    <button className='theme-btn px-10 mt-10' onClick={handleCancel} disabled={cancelLoading}>{cancelLoading ? "Loading..." : "CANCEL"}</button>
                </div>
            </div>
        </>
    )
}
