import TopNavbar from '../components/TopNavbar'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { boardSelectionJson } from '../helpers/constants';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { changeBoard_difficulty } from '../Redux/slices/boardSlice';
import { useState } from 'react';
import SVG from "react-inlinesvg"
import { errorToast, successToast } from '../helpers/commonFunctions';
import { ApiPost } from '../helpers/axiosFunctions';

export default function BoardSelectionPage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [uniqueCode, setUniqueCode] = useState("")
    const { userData } = useSelector(state => state.profileData)
    const { difficulty, versus } = useSelector(state => state.boardData)
    const [loading, setLoading] = useState(false)

    const handlePlayBtn = async (rowData) => {
        if (versus === "Human") {
            setLoading(true)
            await ApiPost("/user/room", { level: rowData?.level })
                .then(() => {
                    navigate("/player-searching")
                })
                .catch((error) => {
                    errorToast(error?.message)
                })
                .finally(() => setLoading(false))
        } else {
            localStorage.setItem('battleVersus', 'AI')
            navigate("/battle")
        }
    }

    const handleHumanCodeSendBtn = async () => {
        if (!uniqueCode) { return errorToast("Unique ID is required") }
        setLoading(true)
        await ApiPost("/user/joinRoom", { id: uniqueCode, receiverId: userData?._id })
            .then(() => {
                navigate("/player-searching");
            })
            .catch((error) => {
                errorToast(error?.message)
            })
            .finally(() => setLoading(false))
    }

    return (
        <div className='w-full'>
            <TopNavbar />
            <div className='flex flex-1 flex-center h-full'>
                <Swiper
                    className='h-full relative'
                    spaceBetween={1}
                    slidesPerView={1}
                    loop
                    modules={[Navigation]}
                    navigation={{
                        nextEl: "#nextBtn",
                        prevEl: "#prevBtn"
                    }}
                >
                    {boardSelectionJson?.map((v, i) => {
                        return <SwiperSlide key={i}>
                            <div className='flex flex-col items-center h-full pt-10 sm:pt-7'>
                                <h1 className='text-4xl sm:text-3xl text-textYellow mb-3 sm:mb-0'>LEVELS {v.level}</h1>
                                {versus === "AI" && <div className='my-2 flex justify-center sm:gap-10 gap-16 w-full'>
                                    <div
                                        className={`${difficulty === "Easy" ? "text-textYellow" : "text-gray-400"} hover:text-textYellow cursor-pointer text-xl sm:text-lg duration-300`}
                                        onClick={() => dispatch(changeBoard_difficulty("Easy"))}
                                    >Easy</div>
                                    <div
                                        className={`${difficulty === "Medium" ? "text-textYellow" : "text-gray-400"} hover:text-textYellow cursor-pointer text-xl sm:text-lg duration-300`}
                                        onClick={() => dispatch(changeBoard_difficulty("Medium"))}
                                    >Medium</div>
                                    <div
                                        className={`${difficulty === "Hard" ? "text-textYellow" : "text-gray-400"} hover:text-textYellow cursor-pointer text-xl sm:text-lg duration-300`}
                                        onClick={() => dispatch(changeBoard_difficulty("Hard"))}
                                    >Hard</div>
                                </div>}
                                <img className={`w-[250px] sm:w-[200px] ${versus === "Human" ? "mt-16" : "mt-3"}`} src={v.image} alt="boardImg" />
                                <div className='flex items-center gap-5 sm:mt-7 mt-10'>
                                    <div className='bg-textYellow rounded-full flex flex-center h-16 w-16'>
                                        <img
                                            src="https://img.freepik.com/premium-vector/young-man-face-avater-vector-illustration-design_968209-13.jpg?semt=ais_hybrid"
                                            alt="avatar"
                                            className='rounded-full h-12 w-12 object-cover'
                                        />
                                    </div>
                                    <div className='text-3xl text-textYellow'>VS</div>
                                    <div className='bg-textYellow rounded-full flex flex-center h-16 w-16'>
                                        <img
                                            src={versus === "AI" ? "/media/images/AI_profile.png" : "https://img.freepik.com/premium-vector/young-man-face-avater-vector-illustration-design_968209-13.jpg?semt=ais_hybrid"}
                                            alt="avatar"
                                            className='rounded-full size-10 object-cover'
                                        />
                                    </div>
                                </div>
                                <button className='theme-btn px-10 mt-10 sm:mt-7' onClick={() => handlePlayBtn(v)} disabled={loading}>{loading ? "Loading..." : "PLAY"}</button>
                                {versus === "AI" && <desc className='text-white/80 mt-5'>No Any Entry Fee. It's Completely Free.</desc>}
                                {versus === "Human" && <>
                                    <div className='mt-5 text-white/80'>My Unique ID: <span className='text-textYellow'>{userData?.uniqueId}</span></div>
                                    <div className='text-white/80 mt-1'>Find Online Friend With There Unique ID </div>
                                    <div className='flex gap-5 mt-2'>
                                        <input
                                            type="text"
                                            className='rounded-full text-center bg-transparent px-5 pb-1.5 pt-2 border border-textYellow text-textYellow focus:outline-none'
                                            placeholder='ENTER UNIQUE ID'
                                            value={uniqueCode}
                                            onChange={({ target }) => setUniqueCode(target.value)}
                                        />
                                        <button
                                            className={`rounded-full text-center bg-transparent px-5 pb-1.5 pt-2 border border-textYellow text-textYellow ${loading ? "text-base" : "text-3xl"}`}
                                            onClick={handleHumanCodeSendBtn}
                                            disabled={!uniqueCode || loading}
                                        >
                                            {loading ? "Loading..." : <SVG src='/media/svg/rightArrowNormal.svg' />}
                                        </button>
                                    </div>
                                </>}
                            </div>
                        </SwiperSlide>
                    })}
                    <button id='prevBtn' className="z-50 absolute top-[250px] sm:top-[200px] text-6xl left-14 sm:left-7 text-textYellow">
                        {"<"}
                    </button>
                    <button id='nextBtn' className="z-50 absolute top-[250px] sm:top-[200px] text-6xl right-14 sm:right-7 text-textYellow">
                        {">"}
                    </button>

                </Swiper>
            </div>
        </div>
    )
}
