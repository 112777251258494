import { Dialog, DialogPanel } from '@headlessui/react'
import { ApiPost } from '../../helpers/axiosFunctions'
import { errorToast } from '../../helpers/commonFunctions'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

export default function AddMoneyModal({ isOpen, setIsOpen, notClosable }) {
    const dispatch = useDispatch()
    const [value, setValue] = useState("")
    const [loading, setLoading] = useState(false)
    // amount customer name account number ifsc code mobile number

    const HandlePay = async () => {
        setLoading(true)
        if (Number(value) < 49) {
            setLoading(false)
            return errorToast("Please enter minimum 50₹")
        }
        await ApiPost("/user/transaction", { amount: value })
            .then((response) => {
                setIsOpen(false)
                window.open(response?.data?.payment_url, "_blank")
            })
            .catch((error) => {
                errorToast(error.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        setValue("")
    }, [isOpen])

    return (
        <>
            <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none font-daydreamer" onClose={() => { if (!notClosable) { setIsOpen(false) } }}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto backdrop-blur duration-300">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <DialogPanel
                            transition
                            className="w-full max-w-[330px] duration-300 ease-out data-[closed]:transform-[scale(50%)] data-[closed]:opacity-0"
                        >
                            <h1 className="text-4xl text-center font-medium text-white">
                                Add Money
                            </h1>
                            <h1 className="text-2xl text-center font-medium text-textYellow text-shadow">
                                (Main Wallet)
                            </h1>
                            <div className=' bg-[url(/public/media/images/selectTournamentBG.png)] bg-contain bg-no bg-no-repeat h-[230px] mt-7 w-[200px] mx-auto text-center'>
                                <input
                                    type="text"
                                    className='mt-[95px] py-2 bg-transparent outline-none text-white text-2xl w-full text-center'
                                    value={value}
                                    onChange={({ target }) => {
                                        if (isNaN(target.value)) return;
                                        setValue(target.value)
                                    }}
                                    autoFocus={true}
                                />
                                <div className='flex flex-center mt-4'>
                                    <button className='theme-btn mx-auto px-7 !pt-1 !text-base' onClick={HandlePay} disabled={loading}>{loading ? "Loading..." : "BUY"}</button>
                                </div>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    )
}