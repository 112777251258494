export const calculateWinner = (squares) => {
  const lines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];
  for (let i = 0; i < lines.length; i++) {
    const [a, b, c] = lines[i];
    if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
      return squares[a];
    }
  }
  return null;
};

// Easy AI (Random move)
export const easyAI = (squares) => {
  const emptyIndexes = squares
    .map((value, index) => (value === null ? index : null))
    .filter((val) => val !== null);
  return emptyIndexes[Math.floor(Math.random() * emptyIndexes.length)];
};

// Medium AI (Prioritize winning/blocking)
export const mediumAI = (squares, ai, player) => {
  const lines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];

  // Check for winning move
  for (let line of lines) {
    const [a, b, c] = line;
    const values = [squares[a], squares[b], squares[c]];
    if (values.filter((v) => v === ai).length === 2 && values.includes(null)) {
      return line[values.indexOf(null)];
    }
  }

  // Check for blocking move
  for (let line of lines) {
    const [a, b, c] = line;
    const values = [squares[a], squares[b], squares[c]];
    if (values.filter((v) => v === player).length === 2 && values.includes(null)) {
      return line[values.indexOf(null)];
    }
  }

  return easyAI(squares); // Fallback to random
};

// Hard AI (Minimax algorithm)
export const hardAI = (squares, isMaximizing, ai, player) => {
  const winner = calculateWinner(squares);
  if (winner === ai) return 10;
  if (winner === player) return -10;
  if (!squares.includes(null)) return 0;

  const playerMoves = squares.filter(square => square === player).length;
  const aiMoves = squares.filter(square => square === ai).length;
  const isFirstAIMove = playerMoves === 1 && aiMoves === 0;
  if (isFirstAIMove && squares[4] === null) {
    return 4; // AI places its first move in the center
  }

  const moves = [];

  for (let i = 0; i < squares.length; i++) {
    if (squares[i] === null) {
      const newSquares = [...squares];
      newSquares[i] = isMaximizing ? ai : player;

      const score = hardAI(newSquares, !isMaximizing, ai, player);

      moves.push({
        index: i,
        score,
      });
    }
  }

  // Choose the best move based on whether it's AI's turn (isMaximizing)
  let bestMove;

  if (isMaximizing) {
    let bestScore = -Infinity;
    for (let move of moves) {
      if (move.score > bestScore) {
        bestScore = move.score;
        bestMove = move.index;
      }
    }
  } else {
    let worstScore = Infinity;
    for (let move of moves) {
      if (move.score < worstScore) {
        worstScore = move.score;
        bestMove = move.index;
      }
    }
  }

  // If it's AI's turn, return the index of the best move, otherwise return the score
  return isMaximizing ? bestMove : moves.find(m => m.index === bestMove).score;
};