import { useEffect, useState } from "react";
import { calculateWinner } from "../../helpers/boardFunctions";
import { useNavigate } from "react-router-dom";
import { infoToast, errorToast, successToast } from "../../helpers/commonFunctions";
import { useSelector } from "react-redux";
import { ApiGet, ApiPost } from "../../helpers/axiosFunctions";

export default function ThreeBoardHuman() {
    const navigate = useNavigate();
    const { userData } = useSelector((state) => state.profileData);
    const [roomData, setRoomData] = useState(null);
    const [playData, setPlayData] = useState({})
    const [loading, setLoading] = useState(false)

    const handleClick = async (index) => {
        if (!playData?.isCurrentTurn) return;

        const newSquares = [...playData?.data];
        newSquares[index] = playData?.yourKey;
        const winner = calculateWinner(newSquares);
        const body = {
            data: newSquares,
            winnerKey: winner
        }
        await ApiPost("/user/playing", body)
            .then((result) => {
                setPlayData(result?.data)
            })
            .catch((error) => {
                errorToast(error?.message);
            })
    };


    const fetchRoomData = async () => {
        setLoading(true)
        try {
            const result = await ApiGet(`/user/getRoom/${userData?.uniqueId}`);
            setRoomData(result?.data);
        } catch (error) {
            errorToast(error?.message);
            if (error?.status === 404) {
                navigate('/home');
            }
        } finally {
            setLoading(false)
        }
    };

    const fetchPlayingData = async () => {
        try {
            const { data } = await ApiGet(`/user/playing`);
            console.log('playing_response', data);
            setPlayData(data);

            if (data?.winnerKey === "X" && data?.winnerKey === data?.yourKey) {
                successToast("Winner is X");
                return true;
            } else if (data?.winnerKey === "O" && data?.winnerKey === data?.yourKey) {
                successToast("Winner is O");
                return true;
            } else if (data?.winnerKey === "X" && data?.winnerKey !== data?.yourKey) {
                errorToast("Winner is X");
                return true;
            } else if (data?.winnerKey === "O" && data?.winnerKey !== data?.yourKey) {
                errorToast("Winner is O");
                return true;
            } else if (data?.winnerKey === "Draw") {
                infoToast("It's a Draw");
                return true;
            }

            return false; // Return false if no conditions match.
        } catch (error) {
            console.log('error_playing', error);
            return false; // Return false in case of an error.
        }
    }

    useEffect(() => {
        let timeoutId;
        if (userData?.uniqueId) {
            fetchRoomData();

            const startFetching = async () => {
                if (userData?.uniqueId) {
                    const isDone = await fetchPlayingData();
                    if (!isDone) {
                        timeoutId = setTimeout(startFetching, 1000);
                    } else {
                        setTimeout(() => {
                            infoToast("Redirecting to board selection page");
                            setTimeout(() => {
                                navigate('/board-selection');
                            }, 3000);
                        }, 3000);
                    }
                }
            };

            startFetching();
        }

        // Cleanup function to stop the timeout when component unmounts
        return () => clearTimeout(timeoutId);
    }, [userData]);

    return (
        <div className="tic-tac-toe">
            {playData?.currentTurnName && <div className='text-2xl text-textYellow mt-7'>{playData?.currentTurnName}'s Turn</div>}

            <div className="board outline outline-[15px] border-[10px] border-primaryPurple outline-black shadow-inner shadow-black text-4xl rounded-sm">
                {playData?.data?.map((square, index) => (
                    <button
                        key={index}
                        className="square odd:bg-[#F6B349] even:bg-[#FB577A]"
                        onClick={() => handleClick(index)}
                    >
                        {(square && square === "X") && <img src="/media/svg/X_in_board.svg" width={50} height={50} />}
                        {(square && square === "O") && <img src="/media/svg/O_in_board.svg" width={50} height={50} />}
                    </button>
                ))}
            </div>

            {/* {winner && <p className="absolute top-1/2 w-full text-center backdrop-blur-sm bg-black/50 text-7xl text-green-500 left-1/2 -translate-x-1/2 -translate-y-1/2">Winner: {winner}</p>}
            {isDraw && !winner && <p className="absolute top-1/2 w-full text-center backdrop-blur-sm bg-black/50 text-7xl text-textYellow left-1/2 -translate-x-1/2 -translate-y-1/2">It's a Draw!</p>} */}

            <div className="flex justify-between mt-14">
                <div className={`flex flex-col items-center px-5 pt-3 rounded-xl border border-transparent ${playData?.currentTurnId === playData?.senderId && "border-white"}`}>
                    <div className='bg-textYellow rounded-full flex flex-center h-16 w-16'>
                        <img
                            src="https://img.freepik.com/premium-vector/young-man-face-avater-vector-illustration-design_968209-13.jpg?semt=ais_hybrid"
                            alt="avatar"
                            className='rounded-full h-12 w-12 object-cover'
                        />
                    </div>
                    <div className="text-center text-textYellow mt-1">
                        {roomData?.senderData?.name} {playData?.senderKey}
                    </div>
                </div>
                <div className={`flex flex-col items-center px-5 pt-3 rounded-xl border border-transparent ${playData?.currentTurnId === playData?.receiverId && "border-white"}`}>
                    <div className='bg-textYellow rounded-full flex flex-center h-16 w-16'>
                        <img
                            src="https://img.freepik.com/premium-vector/young-man-face-avater-vector-illustration-design_968209-13.jpg?semt=ais_hybrid"
                            alt="avatar"
                            className='rounded-full h-12 w-12 object-cover'
                        />
                    </div>
                    <div className="text-center text-textYellow mt-1">
                        {roomData?.receiverData?.name} {playData?.receiverKey}
                    </div>
                </div>
            </div>
        </div>
    );
};