import { Dialog, DialogPanel } from '@headlessui/react'
import { ApiPost } from '../../helpers/axiosFunctions'
import { errorToast } from '../../helpers/commonFunctions'
import { useEffect, useState } from 'react'

export default function WithdrawModal({ isOpen, setIsOpen, userData }) {
    const [loading, setLoading] = useState(false)
    const [tempData, setTempData] = useState({
        amount: "",
        customer_name: "",
        account_number: "",
        ifsc: "",
        customer_mobile: ""
    })

    const handleChange = (target) => {
        setTempData({ ...tempData, [target.name]: target.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        if (userData?.balance <= Number(tempData?.amount)) {
            setLoading(false)
            return errorToast("You can withdraw only upto ₹" + (userData?.balance || 0))
        }
        const body = {
            amount: tempData?.amount,
            customer_name: tempData?.customer_name,
            account_number: tempData?.account_number,
            ifsc: tempData?.ifsc,
            customer_mobile: tempData?.customer_mobile,
        }
        await ApiPost("/user/withdraw", body)
            .then((response) => {
                setIsOpen(false)
                window.open(response?.data?.payment_url, "_blank")
            })
            .catch((error) => {
                errorToast(error.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        setTempData({
            amount: "",
            customer_name: "",
            account_number: "",
            ifsc: "",
            customer_mobile: ""
        })
    }, [isOpen])

    return (
        <>
            <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none font-daydreamer" onClose={() => { setIsOpen(false) }}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto backdrop-blur duration-300">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <DialogPanel
                            transition
                            className="w-full max-w-[330px] duration-300 ease-out data-[closed]:transform-[scale(50%)] data-[closed]:opacity-0"
                        >
                            <h1 className="text-4xl text-center font-medium text-white text-shadow">
                                Withdraw Money
                            </h1>
                            <form onSubmit={handleSubmit} className=' bg-[linear-gradient(158deg,#ffbc00,#ffeeac)] rounded-2xl border-4 border-[#ffde21] outline outline-4 outline-[orange] mt-7 w-full mx-auto p-5 space-y-4'>
                                <div>
                                    <label htmlFor="amount" className='text-lg'>Amount</label>
                                    <input
                                        type="text"
                                        className='py-2 outline-none text-primaryPurple rounded-xl text-xl w-full px-5'
                                        placeholder='Enter Amount'
                                        name='amount'
                                        value={tempData?.amount}
                                        onChange={({ target }) => {
                                            if (isNaN(target.value)) return;
                                            handleChange(target)
                                        }}
                                        autoFocus={true}
                                        required
                                    />
                                </div>
                                <div>
                                    <label htmlFor="customer_name" className='text-lg'>Customer Name</label>
                                    <input
                                        type="text"
                                        className='py-2 outline-none text-primaryPurple rounded-xl text-xl w-full px-5'
                                        placeholder='Enter Customer Name'
                                        name='customer_name'
                                        value={tempData?.customer_name}
                                        onChange={({ target }) => {
                                            handleChange(target)
                                        }}
                                        required
                                    />
                                </div>
                                <div>
                                    <label htmlFor="account_number" className='text-lg'>Account Number</label>
                                    <input
                                        type="text"
                                        className='py-2 outline-none text-primaryPurple rounded-xl text-xl w-full px-5'
                                        placeholder='Enter Account Number'
                                        name='account_number'
                                        value={tempData?.account_number}
                                        onChange={({ target }) => {
                                            if (isNaN(target.value)) return;
                                            handleChange(target)
                                        }}
                                        required
                                    />
                                </div>
                                <div>
                                    <label htmlFor="ifsc" className='text-lg'>IFSC Code</label>
                                    <input
                                        type="text"
                                        className='py-2 outline-none text-primaryPurple rounded-xl text-xl w-full px-5'
                                        placeholder='Enter IFSC Code'
                                        name='ifsc'
                                        value={tempData?.ifsc}
                                        onChange={({ target }) => {
                                            handleChange(target)
                                        }}
                                        required
                                    />
                                </div>
                                <div>
                                    <label htmlFor="customer_mobile" className='text-lg'>Mobile Number</label>
                                    <input
                                        type="text"
                                        className='py-2 outline-none text-primaryPurple rounded-xl text-xl w-full px-5'
                                        placeholder='Enter Mobile Number'
                                        name='customer_mobile'
                                        value={tempData?.customer_mobile}
                                        onChange={({ target }) => {
                                            if (isNaN(target.value)) return;
                                            handleChange(target)
                                        }}
                                        required
                                    />
                                </div>
                                <div className='flex flex-center'>
                                    <button type='submit' className='theme-btn mx-auto px-7 !pt-1 !text-base' disabled={loading}>{loading ? "Loading..." : "WITHDRAW"}</button>
                                </div>
                            </form>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    )
}