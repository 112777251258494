import React, { useEffect, useState } from 'react'
import AudioIcon from '../components/AudioIcon'
import { RxCross2 } from 'react-icons/rx'
import { FiPause } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import ThreeBoardAI from '../components/battle/ThreeBoardAI'
import { useDispatch, useSelector } from 'react-redux'
import ThreeBoardHuman from '../components/battle/ThreeBoardHuman'
import { changeBoard_versus } from '../Redux/slices/boardSlice'
export default function BattlePage() {
    const { versus } = useSelector(state => state.boardData)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleClose = () => {
        navigate("/home")
    }

    useEffect(() => {
        const localDataVersus = localStorage.getItem('battleVersus')
        if (localDataVersus) {
            dispatch(changeBoard_versus(localDataVersus))
        }
    }, [])

    return (
        <>
            <AudioIcon />
            <div className="absolute top-7 left-7 text-white border border-textYellow rounded-lg p-1 cursor-pointer" onClick={handleClose}>
                <RxCross2 size={25} />
            </div>
            <div className="absolute top-20 left-7 text-white border border-textYellow rounded-lg p-1.5 cursor-pointer">
                <FiPause size={23} />
            </div>
            <div className="flex flex-col flex-1 flex-center text-center">
                <div id="board" className='mt-7'>
                    {versus === "AI" && <ThreeBoardAI />}
                    {versus === "Human" && <ThreeBoardHuman />}
                </div>
            </div>
        </>
    )
}
