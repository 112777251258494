import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Layout from "./pages/Layout";
import Login from "./pages/Login";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Toaster } from "react-hot-toast";
import StartPage from "./pages/StartPage";
import LayoutWithoutNavbar from "./pages/LayoutWithoutNavbar";
import OTPVerification from "./pages/OTPVerification";
import Error404 from "./pages/Error404";
import SignUp from "./pages/SignUp";
import BoardSelectionPage from "./pages/BoardSelectionPage";
import BattlePage from "./pages/BattlePage";
import RulesPage from "./pages/RulesPage";
import PlayerSearchingPage from "./pages/PlayerSearchingPage";
import TournamentBattlePage from "./pages/TournamentBattlePage";
import WalletPage from "./pages/WalletPage";
import ProfilePage from "./pages/ProfilePage";

function App() {
  function PrivateRoute({ children }) {
    const isAuthenticated = localStorage.getItem('zero_kataa_user');
    return isAuthenticated ? children : <Navigate to="/auth/login" />;
  }

  return (
    <GoogleOAuthProvider clientId="608404855674-dsv21er3r1kpbhbc8cvji1upjcq5n8u5.apps.googleusercontent.com">
      <Toaster position="top-center" />
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <LayoutWithoutNavbar>
                <StartPage />
              </LayoutWithoutNavbar>
            }
          />
          <Route
            path="/auth/login"
            element={
              <LayoutWithoutNavbar>
                <Login />
              </LayoutWithoutNavbar>
            }
          />
          <Route
            path="/auth/signup"
            element={
              <LayoutWithoutNavbar>
                <SignUp />
              </LayoutWithoutNavbar>
            }
          />
          <Route
            path="/auth/otp-verification"
            element={
              <LayoutWithoutNavbar>
                <OTPVerification />
              </LayoutWithoutNavbar>
            }
          />

          {/* Private routes */}
          <Route path="/" element={<Layout />}>
            <Route
              path="/Home"
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route
              path="/rules"
              element={
                <PrivateRoute>
                  <RulesPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/board-selection"
              element={
                <PrivateRoute>
                  <BoardSelectionPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/player-searching"
              element={
                <PrivateRoute>
                  <PlayerSearchingPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/battle"
              element={
                <PrivateRoute>
                  <BattlePage />
                </PrivateRoute>
              }
            />
            <Route
              path="/tournament-battle"
              element={
                <PrivateRoute>
                  <TournamentBattlePage />
                </PrivateRoute>
              }
            />
            <Route
              path="/wallet"
              element={
                <PrivateRoute>
                  <WalletPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <PrivateRoute>
                  <ProfilePage />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path="/*"
            element={
              <LayoutWithoutNavbar>
                <Error404 />
              </LayoutWithoutNavbar>
            }
          />
        </Routes>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

export default App;
