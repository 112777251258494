import React, { useState } from 'react'
import TransactionPage from './TransactionPage'
import { useSelector } from 'react-redux'
import WithdrawModal from '../components/Modal/WithdrawModal'

export default function WalletPage() {
    const { userData } = useSelector(state => state.profileData)
    const [withdrawModal, setWithdrawModal] = useState(false)
    return (
        <div className='w-full flex flex-col items-center px-7 text-center'>
            <h1 className='text-textYellow text-3xl my-7'>Wallets</h1>
            <div className="flex gap-4 w-full">
                <div className='w-1/2 bg-textYellow rounded-xl p-2 shadow-inner shadow-black border border-primaryPurple outline'>
                    <div className='text-xl'>Main Wallet</div>
                    <div className='text-white text-xl text-shadow'>₹{userData?.balance || 0}</div>
                </div>
                <div className='w-1/2 bg-textYellow rounded-xl p-2 shadow-inner shadow-black border border-primaryPurple outline'>
                    <div className='text-xl'>Bonus Wallet</div>
                    <div className='text-white text-xl text-shadow'>₹{userData?.bonusBalance || 0}</div>
                </div>
            </div>
            <button className='theme-btn !py-1 px-3 mt-5 text-sm cursor-pointer' onClick={() => setWithdrawModal(true)}>Withdraw</button>
            <TransactionPage />
            <WithdrawModal
                isOpen={withdrawModal}
                setIsOpen={setWithdrawModal}
                userData={userData}
            />
        </div>
    )
}
