import { Dialog, DialogPanel } from '@headlessui/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { HiMiniCheckBadge } from "react-icons/hi2";
import { GiCancel } from "react-icons/gi";
import { ApiGet } from '../../helpers/axiosFunctions';
import { TbFidgetSpinner } from "react-icons/tb";
import { errorToast } from '../../helpers/commonFunctions';
import { useDispatch } from 'react-redux';
import { changeProfile_UserData } from '../../Redux/slices/profileSlice';

export default function PaymentResponseModal() {
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [result, setResult] = useState("")
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = new URLSearchParams(window.location.search).get("from_payment")
    const handleClose = () => {
        navigate("/home")
        setIsOpen(false)
    }

    const checkPaymentStatus = async () => {
        await ApiGet("/user/checkTransaction")
            .then(async () => {
                setResult("success")
                await ApiGet("/user/profile")
                    .then((result) => {
                        dispatch(changeProfile_UserData(result?.data))
                    })
                    .catch((error) => {
                        errorToast(error?.message)
                    })
            })
            .catch(() => {
                setResult("failure")
            })
            .finally(() => {
                setLoading(false)
                setTimeout(() => {
                    handleClose()
                }, 3000);
            })
    }

    useEffect(() => {
        if (params) {
            setIsOpen(true)
            checkPaymentStatus()
        }
    }, [params])

    return (
        <>
            <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none font-daydreamer" onClose={handleClose}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto backdrop-blur-xl duration-300">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <DialogPanel
                            transition
                            className="w-full max-w-[330px] duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
                        >
                            {loading &&
                                <TbFidgetSpinner className="text-textYellow text-9xl animate-spin mx-auto" />
                            }
                            {result === "success" && <HiMiniCheckBadge className={`${result === "success" ? "text-green-500" : "text-red-500"} mx-auto text-9xl animate-bounce my-7`} />}
                            {result === "failure" && <GiCancel className={`${result === "success" ? "text-green-500" : "text-red-500"} mx-auto text-9xl animate-pulse my-7`} />}
                            <h1 className="text-4xl text-center font-medium text-white text-shadow">
                                {result === "success" && "Payment Successfully"}
                                {result === "failure" && "Payment Failed !!!"}
                            </h1>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    )
}